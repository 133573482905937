import React from "react"
import fiveStarRating from '../../images/stars-5.svg';
import trustpilotLogo from '../../images/trustpilot-logo-dark.svg';
import invited from '../../images/invited-icon.png';

const Trustpilot = ({
    content,
    type = 'original'
}) => {
    const advertorialTrustPilot = type === 'advertorial' ? (
        <div className="cm-trustpilot__wrapper">
            <div className='l-grid__row cm-trustpilot__info l-spacing__m-t--40'>
                <div className='l-grid__col'>
                    <div className='l-grid__row'>
                        <div className='l-grid__col cm-trustpilot__star-rating'>
                            <img src={fiveStarRating} alt='Five star ratings' />
                        </div>
                        <div className='l-grid__col cm-trustpilot__invited'>
                            <img src={invited} alt='Five star ratings' />
                            <p>Invited</p>
                        </div>
                    </div>
                </div>
                <div className='l-grid__col'>
                    <div className='l-grid__row'>
                        <div className='l-grid__col cm-trustpilot__date'>
                            <p>{content.date}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='l-grid__row'>
                <div className='l-grid__col-xs-12 cm-trustpilot__title'>
                    <h2>{content.title}...</h2>
                </div>
            </div>
            <div className='l-grid__row'>
            <div className='l-grid__col-xs-12 cm-trustpilot__quote'>
                    <p dangerouslySetInnerHTML={{ __html: content.quote }} />
                </div>
            </div>
        </div>
    ) : null;

    const originalTrustPilot = type === 'original' ? (
        <div className="cm-trustpilot__wrapper">
            <div className='l-grid__row'>
                <div className='l-grid__col-xs-12 cm-trustpilot__quote'>
                    <p><span>"</span>{content.quote}<span>"</span></p>
                </div>
            </div>
            <div className='l-grid__row l-spacing__m-b--16'>
                <div className='l-grid__col cm-trustpilot__star-rating'>
                    <img src={fiveStarRating} alt='Five star ratings' />
                </div>
                <div className='l-grid__col cm-trustpilot__name'>
                    <p>by: {content.name}</p>
                </div>
            </div>
            <div className='l-grid__row'>
                <div className='l-grid__col-xs-12 cm-trustpilot__logo'>
                    <img src={trustpilotLogo} alt='Trustpilot Logo' />
                </div>
            </div>
        </div>
    ) : null;
    return (
        <div className={`cm-trustpilot ${type === 'advertorial' ? 'cm-trustpilot--advertorial' : null}`}>
            {originalTrustPilot}
            {advertorialTrustPilot}
        </div>
    );
}

export default Trustpilot;
